import React from 'react'

// props interface
interface DocumentProps {
  title: string
  children?: React.ReactNode
}

const Document: React.FC<DocumentProps> = ({ title, children }) => {
  return (
    <div className="pt-24 pb-12">
      <div className="flex flex-wrap flex-col justify-around items-center">
        <div className="flex flex-col w-full md:w-3/5 justify-center items-start text-left">
          <h1 className="mx-4 my-4 text-2xl sm:text-3xl font-bold leading-tight">
            {title}
          </h1>

          <section className="m-4">{children}</section>
        </div>
      </div>
    </div>
  )
}

export default Document
