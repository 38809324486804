import React from 'react'

import DefaultLayout from '../../components/Layout/default'
import Document from '../../components/Legal/Document'
import SEO from '../../components/SEO'

const LegalPrivacyPolicyPage: React.FC = () => {
  return (
    <DefaultLayout title="プライバシーポリシー">
      <SEO
        title="プライバシーポリシー"
        keywords={['BADDY', '筋トレ', 'ワークアウト', 'ジム']}
      />

      <div className="container mx-auto">
        <Document title="プライバシーポリシー">
          <h2 className="mt-4">当サイト</h2>

          <p>当サイトのアドレスはhttps://gonjitti.com です。</p>

          <h2 className="mt-4">当サイトの個人データと収集の理由</h2>

          <p>
            当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
            このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
          </p>

          <h2 className="mt-4">広告の配信について</h2>

          <p>
            当サイトは第三者配信の広告サービス「Google Adsense
            グーグルアドセンス」を利用しています。
          </p>

          <p>
            広告配信事業者は、ユーザーの興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。
          </p>

          <p>
            Cookie（クッキー）を無効にする設定およびGoogleアドセンスに関する詳細は「広告
            – ポリシーと規約 – Google」をご覧ください。
            第三者がコンテンツおよび宣伝を提供し、訪問者から直接情報を収集し、訪問者のブラウザにCookie（クッキー）を設定したりこれを認識したりする場合があります。{' '}
          </p>

          <h2 className="mt-4">アフィリエイトについて</h2>

          <p>
            当サイトの商品リンクには一部Amazonアソシエイトプログラムに基づいた外部リンクが設定されています。
          </p>

          <p>
            こちらはユーザーがどのページからAmazonに流入し、購入を行ったかを計測するので、秘匿情報については取得しません。
          </p>

          <h2 className="mt-4">オーナーの連絡先</h2>

          <p>gonjitti@gmail.com</p>
        </Document>
      </div>
    </DefaultLayout>
  )
}

export default LegalPrivacyPolicyPage
